<template>
  <div>
    <b-sidebar
      id="add-new-career-sidebar"
      :visible="isShow"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @hidden="resetForm"
      @change="(val) => $emit('update:is-show', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0 text-primary font-weight-bolder">
            {{ modalMode === 'create' ? 'Tạo ngành nghề' : 'Thông tin ngành nghề' }}
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />

        </div>

        <div class="p-2">
          <dynamic-form
            :id="'career-form-name'"
            :value="career.name"
            :type="'text'"
            :label="'Tên ngành nghề'"
            placeholder="Tên ngành nghề"
            required="required"
            @update-value="val => career.name = val"
          />

          <!-- Form Actions -->
          <div class="d-flex mt-2 justify-content-between flex-wrap">

            <div class="d-flex justify-content-end align-items-start flex-grow-1 ml-2">
              <b-button
                v-if="modalMode === 'create'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                @click="$emit('submit', modalMode, career)"
              >
                Tạo
              </b-button>

              <b-button
                v-if="modalMode === 'edit'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                @click="$emit('submit', modalMode, career)"
              >
                Lưu
              </b-button>

              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Hủy
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { BButton, BFormCheckbox, BSidebar } from 'bootstrap-vue'
import { computed, ref, watch } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import DynamicForm from '@core/dynamic-form/DynamicForm.vue'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BButton,
    DynamicForm,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isShow',
    event: 'update:is-show',
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    modalMode: {
      type: String,
      default: null,
    },
    isShow: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
  },
  setup(props) {
    const career = ref({ name: '' })
    const roles = computed(() => store.state.app.roles)

    const resetForm = () => {
      Object.assign(career.value, { name: '' })
    }

    const branches = computed(() => store.state.app.branches)

    watch(() => props.value, n => {
      if (!n) {
        resetForm()
      } else {
        Object.assign(career.value, n)
      }
    }, { immediate: true })

    return {
      career,
      roles,
      branches,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';

#add-new-career-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

</style>
