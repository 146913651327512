import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCareers: (ctx, q) => axiosIns.get(`/api.career${q || ''}`),
    fetchPublicCareers: (ctx, q) => axiosIns.get(`/api.career/public${q || ''}`),
    fetchCareer: (ctx, { id }) => axiosIns.get(`/api.career/${id}`),
    createCareer: (ctx, data) => axiosIns.post('/api.career', data),
    updateCareer: (ctx, data) => axiosIns.put(`/api.career/${data.id}`, data),
    updateCareerLimit: (ctx, data) => axiosIns.put(`/api.career/${data.id}/limit`, data),
    updateMe: (ctx, data) => axiosIns.put('/api.career/me', data),
    changePassword: (ctx, data) => axiosIns.post('/api.career/change-password', data),
    adminChangePassword: (ctx, data) => axiosIns.post('/api.career/admin-change-password', data),
    deleteCareer: (ctx, { id }) => axiosIns.delete(`/api.career/${id}`),
    fetchRoles: (ctx, q) => axiosIns.delete(`/api.career/${q || ''}`),
    fetchReportCareerTime: (ctx, q) => axiosIns.get(`/api.report/career${q || ''}`),
  },
}
