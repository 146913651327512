import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import http from '@/global/http'
import util from '@/global/util'
import constant from '@/global/const'

export default function useCareersList() {
  const careers = ref([])
  const careersOrigin = ref([])
  const currentCareer = ref({})
  const isPopupCareerModal = ref(false)
  const careerModalMode = ref('create')
  const careerModalData = ref({})
  const showImage160 = ref([])

  const searchId = ref('')
  const searchRole = ref('')
  const searchQuery = ref('')

  // Table Handlers
  const tableColumnsCareers = [
    {
      label: 'Mã',
      key: 'id',
      tdClass: 'text-primary',
    },
    {
      label: 'Ngành nghề',
      key: 'name',
    },
    {
      label: 'Ngày tạo',
      key: 'created_at',
      formatter: value => util.formatDate(value),
    },
    {
      label: 'Hành động',
      key: 'actions',
      tdClass: 'text-right',
      thClass: 'text-right',
    },
  ]
  const currentPage = ref(1)
  const totalContracts = ref(0)
  const resultTitle = ref('')

  const fetchCareers = filter => {
    const queryString = http.buildQuery(filter || {
      page: 0,
      perpage: 999,
    }) || ''
    return http.handle(store.dispatch('career/fetchCareers', queryString), res => {
      careersOrigin.value = res.data || []
    })
  }

  const fetchCareer = id => http.handle(store.dispatch('career/fetchCareer', { id }), res => {
    careerModalData.value = res.data
    isPopupCareerModal.value = true
  })

  const updateCareer = data => http.handle(store.dispatch('career/updateCareer', data), res => {
    careerModalData.value = res.data
    Object.assign(careers.value.find(u => u.id === res.data.id), res.data)
  })

  const createCareer = data => http.handle(store.dispatch('career/createCareer', data), res => {
    isPopupCareerModal.value = false
    careers.value.unshift(res.data)
  })

  const resetModal = () => {
    Object.assign(careers.value, { name: '' })
  }
  const deleteCareer = career => http.handle(store.dispatch('career/deleteCareer', career), () => {
    const idx = careers.value.findIndex(u => u.id === career.id)
    careers.value.splice(idx, 1)
  })

  const filterCareers = () => {
    if (!searchId.value && !searchQuery.value && !searchRole.value) {
      careers.value = careersOrigin.value
      return
    }
    careers.value = careersOrigin.value.filter(u => {
      if (searchId.value) {
        const qid = searchId.value.toString()
          .toLowerCase()
        if (qid && (u.id.toString()
          .toLowerCase() === qid)) {
          return true
        }
      }
      if (searchRole.value) {
        const qrole = searchRole.value.toLowerCase()
        if (qrole && (u.role.toLowerCase() === qrole)) return true
      }
      if (searchQuery.value) {
        const q = searchQuery.value?.toLowerCase()
        return q && (u.fullname?.toLowerCase()
          .includes(q) || u.phone?.toLowerCase()
            .includes(q) || u.email?.toLowerCase()
              .includes(q))
      }
      return false
    })
  }

  const searchKey = () => {
    filterCareers()
  }

  watch([careersOrigin, searchRole], () => {
    filterCareers()
  })

  return {
    currentCareer,
    tableColumnsCareers,
    currentPage,
    totalContracts,
    resultTitle,
    showImage160,
    careers,
    careersOrigin,
    searchRole,
    searchId,
    searchQuery,
    isPopupCareerModal,
    careerModalMode,
    careerModalData,
    searchKey,
    fetchCareers,
    fetchCareer,
    updateCareer,
    createCareer,
    deleteCareer,
    resetModal,
  }
}
