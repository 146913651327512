<template>
  <div>

    <b-card
      no-body
      class="custom-border-top-1px"
    >
      <div class="card-header">
        <b-badge
          variant="light-primary"
          class="font-small-4 px-1 py-50"
        >
          DANH SÁCH NGÀNH NGHỀ ({{ careers.length }})
        </b-badge>
        <b-button
          v-if="ability().can('create', 'Career')"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="text-nowrap"
          size="sm"
          @click="popupAddCareer"
        >
          <feather-icon
            class="text-white"
            icon="PlusIcon"
          />
          Ngành nghề
        </b-button>
      </div>
      <b-table
        ref="table-careers"
        class="position-relative px-2"
        responsive
        primary-key="id"
        show-empty
        hover
        small
        empty-text="Không có kết quả nào"
        :items="careers"
        :fields="tableColumnsCareers"
      >
        <!-- Column: Hành động -->
        <template #cell(actions)="data">
          <b-button
            v-if="ability().can('delete', 'Career')"
            size="sm"
            :disabled="data.item.role === 'admin' || data.item.role === 'super'"
            variant="outline-primary"
            class="mr-50"
            @click="popupEditCareer(data.item)"
          >Sửa
          </b-button>
          <b-button
            v-if="ability().can('edit', 'Career')"
            size="sm"
            :disabled="data.item.role === 'admin' || data.item.role === 'super'"
            variant="outline-danger"
            @click="openModalConfirmDeleteCareer(data.item)"
          >Xóa
          </b-button>
        </template>
      </b-table>
    </b-card>

    <career-modal-info
      :is-show.sync="isPopupCareerModal"
      :modal-mode="careerModalMode"
      :value="careerModalData"
      @submit="submitForm"
    />

    <b-modal
      ref="modal-confirm-delete-career"
      cancel-title="Hủy"
      ok-title="Xác nhận"
      centered
      header-text-variant="danger"
      title="Xác nhận xóa ngành nghề!"
      @hidden="resetModal"
      @ok="deleteCareer(currentCareer)"
    >
      <b-card-text>
        Bạn thực sự muốn <span
          class="text-danger"
        >xóa {{ (ROLES.find(item => item.value === currentCareer.role) || { text: '' }).text.toLowerCase() }}</span>?
        <br>
        <span
          class="text-primary font-weight-bolder"
        >{{ currentCareer.fullname || currentCareer.phone || currentCareer.careername }}</span>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BRow,
  BTable,
  VBTooltip,
  BAvatar, BMediaAside,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import ability from '@/libs/acl/ability'
import { subject } from '@casl/ability'
import careerStoreModule from '../careerStoreModule'
import useCareersList from './useCareersList'
import CareerModalInfo from './SidebarCareerDetail.vue'

export default {
  components: {
    CareerModalInfo,
    BCardBody,
    BInputGroup,
    BCardText,
    BInputGroupAppend,
    BFormInput,
    BCard,
    vSelect,
    BRow,
    BCol,
    BButton,
    BTable,
    BAvatar,
    BMediaAside,
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      ability,
      subject,
    }
  },
  created() {
    this.fetchCareers()
    this.$store.dispatch('app/fetchBranches')
  },
  methods: {
    handleRowClick(row) {
      // this.popupEdit(row)
      this.$router.push({
        name: 'career-detail',
        params: { id: row.id },
      })
    },
    openModalConfirmDeleteCareer(u) {
      this.currentCareer = u
      this.$refs['modal-confirm-delete-career'].show()
    },
    submitForm(modalMode, modalData) {
      if (modalMode === 'create') {
        this.$call(this.createCareer({ ...modalData, id: '' }), true)
      }
      if (modalMode === 'edit') {
        this.$call(this.updateCareer(modalData), true)
      }
    },
    popupAddCareer() {
      this.careerModalMode = 'create'
      this.isPopupCareerModal = true
      this.careerModalData = null
    },
    popupEditCareer(currentData) {
      this.careerModalMode = 'edit'
      this.isPopupCareerModal = true
      this.careerModalData = currentData
    },
    popupEdit(career) {
      this.careerModalMode = 'edit'
      this.$call(this.fetchCareer(career.id))
    },
  },
  setup() {
    const CAREER_STORE_MODULE_NAME = 'career'
    if (!store.hasModule(CAREER_STORE_MODULE_NAME)) store.registerModule(CAREER_STORE_MODULE_NAME, careerStoreModule)
    onUnmounted(() => {
      if (store.hasModule(CAREER_STORE_MODULE_NAME)) store.unregisterModule(CAREER_STORE_MODULE_NAME)
    })

    return {
      ...useCareersList(),
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.card-body .card-body {
  padding: .5rem;
}
</style>
